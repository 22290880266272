<template>
  <div class="basic_data_edit">
    <div class="data_content data-content-form scrollbar">
      <!-- 按钮 -->
      <div class="botton_box">
        <div class="button_list">
          <sn-button
            :snButton="snButton"
            @handleChange="handleChange"
          ></sn-button>
        </div>
      </div>
      <!-- 表单 -->
      <el-form
        ref="formdata"
        class="demo-form-inline form_row"
        :inline="true"
        :model="formdata.saveJson"
        size="small"
        label-width="200px"
        :rules="rules"
      >
        <el-form-item label="预案名称" prop="YAMC">
          <el-input
            v-model="formdata.saveJson.YAMC"
            placeholder="请输入预案名称"
            :maxlength="50"
            onkeydown="if(event.keyCode==32) return false"
            :disabled="!isEdit"
          ></el-input>
        </el-form-item>

        <el-form-item label="预案预警等级" prop="YJDJ">
          <el-select
            v-model="formdata.saveJson.YJDJ"
            placeholder="请选择预案预警等级"
            filterable
            :disabled="!isEdit"
          >
            <el-option
              v-for="(item, index) in yayjdjList"
              :key="index"
              :label="item.NAME"
              :value="item.CODE"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="预案生成时间" prop="CJSJ">
          <!-- type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss" -->
          <el-date-picker
            v-model="formdata.saveJson.CJSJ"
            placeholder="请选择预案生成时间"
            type="date"
            value-format="yyyy-MM-dd"
            :disabled="!isEdit"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="说明" prop="MS">
          <el-input
            v-model="formdata.saveJson.MS"
            type="textarea"
            placeholder="请输入说明"
            :maxlength="50"
            :show-word-limit="true"
            onkeydown="if(event.keyCode==32) return false"
            :disabled="!isEdit"
          ></el-input>
        </el-form-item>
        <el-form-item class="must-fill" label="预案附件" prop="FILELIST">
          <el-upload
            v-model="formdata.saveJson.FILELIST"
            v-if="isEdit"
            :action="`${uploadURL}UpLoad/FileSave?LJ=YJYA`"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="formdata.saveJson.FILELIST"
            :limit="1"
            :class="{
              disableShow: formdata.saveJson.FILELIST.length === 1,
            }"
            :on-success="handleSuccess"
          >
            <el-button
              size="small"
              type="primary"
              v-if="!formdata.saveJson.FILELIST.length"
              >点击上传</el-button
            >
            <div
              slot="tip"
              class="el-upload__tip"
              v-if="!formdata.saveJson.FILELIST.length"
            >
              上传说明：只能上传1个附件！
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import snButton from "../warning_common/edit-button.js";
import infoMixin from "../warning_common/info_mixin.js";
import { mapActions } from "vuex";
export default {
  name: "warning_plan_form",
  mixins: [infoMixin],
  data() {
    return {
      notNeedFile: true,
      snButton: snButton,
      formdata: {
        saveJson: {
          YAMC: "", // 预案名称
          YJDJ: "", // 预案预警等级
          CJSJ: "", // 预案生成时间
          MS: "", // 说明
          CJRID: localStorage.userId,
          FILELIST: [], //文件
        },
        heritageId: "",
        userName: "",
        userId: "",
      },
      yayjdjList: [], // 预案预警等级列表

      rules: {
        YAMC: [
          {
            required: true,
            message: "请输入预案名称",
            trigger: "blur",
          },
        ],
        YJDJ: [
          {
            required: true,
            message: "请选择预案预警等级",
            trigger: "change",
          },
        ],
        CJSJ: [
          {
            required: true,
            message: "请选择预案生成时间",
            trigger: "change",
          },
        ],
        FILELIST: [
          {
            required: true,
            message: "请选择附件",
            trigger: "change",
          },
        ],
      },
      uploadURL: window.REQUEST_URL,
    };
  },
  mounted() {
    this.snButton.buttonData[1].isShow = false;
    this.getYayjdjList();
  },
  methods: {
    ...mapActions([
      "GetEnumIdList", // 获取预警等级的枚举
      "SaveYjyaDataInfo", // 新建/修改评估对象
      "ValidExpression",
    ]),
    // 获取预案预警等级列表
    async getYayjdjList() {
      this.yayjdjList = [];
      let result = await this.GetEnumIdList({
        enumId: "212cb2aa-748a-11ea-bd8d-000c2977b7fd",
      });
      this.yayjdjList = result;
    },
    // 获取数据
    getFromData() {
      this.formdata.saveJson.FILELIST = [];
      if (this.$route.query.data) {
        let formData = JSON.parse(this.$route.query.data);
        Object.assign(this.formdata.saveJson, formData);
        if (this.formdata.saveJson.WJLJ) {
          this.formdata.saveJson.FILELIST.push({
            name: this.formdata.saveJson.WJMC,
            url: this.formdata.saveJson.WJLJ,
          });
        }
      }
    },
    // 预览
    handlePreview(file) {
      if (!file) return;
      if (file.url) {
        window.open(file.url);
      } else if (file.CCLJ) {
        window.open(file.CCLJ);
      } else if (
        file.response &&
        file.response.ResultValue &&
        file.response.ResultValue.length > 0 &&
        file.response.ResultValue[0].FilePath
      ) {
        window.open(file.response.ResultValue[0].FilePath);
      }
    },
    // 删除
    handleRemove(file, fileList) {
      this.formdata.saveJson.FILELIST = [];
      this.formdata.saveJson.WJLJ = "";
      this.formdata.saveJson.WJMC = "";
    },
    // 上传成功
    handleSuccess(res, file) {
      this.formdata.saveJson.FILELIST.push(file);
      this.formdata.saveJson.WJMC = res.ResultValue[0].FileName;
      this.formdata.saveJson.WJLJ = res.ResultValue[0].FilePath_ex;
      this.$refs.formdata.validate();
    },
    // 保存
    async saveData() {
      if (this.formdata.saveJson.WJLJ) {
        let res = await this.SaveYjyaDataInfo({
          yjysDto: JSON.stringify(this.formdata.saveJson),
        });
        if (res.ISSUCCESS) {
          this.common.showMsg(res.RESULTDESCRIPTION, "success");
          this.returnList();
        } else {
          this.common.showMsg(res.RESULTDESCRIPTION, "error");
        }
      } else {
        this.common.showMsg("请上传预案附件", "warning");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.data-content-form {
  width: 100%;
  height: calc(100% - 150px);
}
</style>
